

.App-logo {
  height: 97vmin;
  filter: invert(95%) sepia(26%) saturate(2%) hue-rotate(73deg) brightness(115%) contrast(100%);
  transform: scale(-1,1);
}



@keyframes flicker {
  0% {  filter: invert(75%) sepia(16%) saturate(2%) hue-rotate(73deg) brightness(115%) contrast(100%); }
  25% { filter: invert(55%) sepia(16%) saturate(2%) hue-rotate(73deg) brightness(115%) contrast(100%); }
  50% { filter: invert(35%) sepia(16%) saturate(2%) hue-rotate(73deg) brightness(115%) contrast(100%); }
  75% {  filter: invert(55%) sepia(16%) saturate(2%) hue-rotate(73deg) brightness(115%) contrast(100%); }
  100% {  filter: invert(65%) sepia(16%) saturate(2%) hue-rotate(73deg) brightness(115%) contrast(100%); }

}


.container {
  magin: 0 auto;
  text-align: center;
}




